:root {
    --border-color: #ff5722 !important; /* Customize border color */
    --border-width: 2px !important;     /* Customize border width */
    --border-radius: 8px !important;    /* Customize border radius */
    /* Override more variables as needed */
}

#calendar-timeonly .p-inputtext{
    border:unset !important;
    border-radius:0% !important;
    border-bottom:2px solid #7ebcf4 !important;
}
 
.p-column-filter-row-items{
    padding:2px !important;
}

:has(.react-select-2-listbox) {
    z-index: 100 !important;
  }